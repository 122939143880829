<template>
  <div class="ad-page">
    <!-- <img v-for="(item,i) in imgList" :key="i"
      class="ad-img" :src="config.P1_IMG_DOMAIN + item">
    </img> -->
    <img v-for="(item,i) in imgList" :key="i"
      class="ad-img" 
      :src="config.P1_IMG_DOMAIN + item" />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import ajax from '@/utils/ajax.js'
import config from '@/utils/config'
export default {
  components: {
  },
  data() {
    return {
      config,
      id: '',

      data: {},
      imgList: [],
    }
  },
  created() {
    // localStorage.setItem('backpagefullpath', encodeURIComponent(this.$route.fullPath))
  },
  mounted() {
    this.id = this.$route.query.id
    
    this.getDetail()
  },
  destroyed() {

  },
  methods: {

    async getDetail() {
      const {data, code} = await ajax.post('/EventAd/detail', {
        id: this.id
      })

      if(code == 0) {
        if(data.title){
          document.title = data.title
        }

        this.data = data
        try{
          const content = JSON.parse(data.content)
          if(content && content.img_list){
            this.imgList = content.img_list
          }
        }catch(e){
          console.log("json解析失败", e)
        }
        
        // this.shareFn()
      }
    },

    async shareFn() {
      const params = {
        url: window.location.href
      }
      const {data, code} = await ajax.post('/JsSdk/getH5SignPackage', params)
      if(code == 0) {
        wx.config({
          debug: false,
          appId: data.app_id,
          timestamp: data.timestamp,
          nonceStr: data.nonce_str,
          signature: data.signature,
          jsApiList: ['updateAppMessageShareData','updateTimelineShareData'],
          openTagList: ['wx-open-launch-weapp']
        })
        
        wx.ready(() => {
            //分享朋友
            wx.updateAppMessageShareData({
                title: this.data.share_title || this.data.name, // 分享标题
                desc: this.data.share_describe, // 分享描述
                link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: this.data.thumbnail_url || config.SHARE_IMG_URL, // 分享图标
                success: function () {
                    console.info("成功")
                    // 设置成功
                },
                fail: function (erres) {
                    console.info('失败：', erres)
                }
            })
            //分享到 朋友圈
            wx.updateTimelineShareData({
                title: this.data.share_title || this.data.name, // 分享标题
                link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: this.data.thumbnail_url || config.SHARE_IMG_URL, // 分享图标
                success: function () {
                    console.info("成功")
                    // 设置成功
                },
                fail: function (erres) {
                    console.info('失败：', erres)
                }
            })
        })
        wx.error(function(e) {
          console.log('weixin error', e)
        })
      }
    }
  }
}
</script>

<style lang="scss">

.ad-page {
  font-size: 0;
  
  .ad-img{
    width: 100%;
  }

  .sharetip {
    font-size: rem(14);
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
    position: fixed;
    right: rem(10);
    top: rem(50);
    padding: rem(6) rem(20);
    border-radius: rem(15);
    background: rgba(#333333, 0.8);
    z-index: 888888;
  }
}
</style>